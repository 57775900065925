var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-video" }, [
    _vm.sourceVideoId
      ? _c("div", [_c("div", { attrs: { id: "player" } })])
      : _c("div", { staticClass: "no-text" }, [_vm._v("暂无视频")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }